import React, { useEffect, useState } from "react"
import Card from "./card"
import moment from "moment"
import getWindowSize from "../utils/getWindowSize"

const breakPoints = [640, 960]

export default ({ articles }) => {
  const [{ leftArticles, rightArticles }, setOrder] = useState({ leftArticles: [], rightArticles: [] })
  const [maxWidth, setMaxWidth] = useState(null)
  const { width } = getWindowSize()

  const filterArticles = articles.filter(article => moment(article.node.published_at).isBefore(moment()))
  const orderArticles = filterArticles.sort((a, b) => moment(a.node.published_at).isBefore(moment(b.node.published_at)) ? 1 : -1)
  useEffect(() => {
    const point = (breakPoints.filter(point => width < point)[0] || 0)
    setMaxWidth(point)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  useEffect(() => {
    if (maxWidth !== null) {
      if (width < 640) {
        setOrder(getOrderSmall(orderArticles))
      } else if (width < 960) {
        setOrder(getOrderMedium(orderArticles))
      } else {
        setOrder(getOrderBig(orderArticles))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxWidth])
  return (
    <div>
      <div className="uk-child-width-1-2@s" data-uk-grid>
        <div uk-scrollspy="cls: uk-animation-fade; target: .fade; delay: 300; repeat: false">
          <div className="uk-grid-match" data-uk-grid>
            {leftArticles.map((article) => {
              return (
                <Card article={article} key={`article__${article.node.id}`} className="fade"/>
              )
            })}
          </div>
        </div>
        <div uk-scrollspy="cls: uk-animation-fade; target: .fade; delay: 100; repeat: false">
          <div className="uk-child-width-1-2@m" data-uk-grid="masonry: true">
            {rightArticles.map((article, key) => {
              return (
                <Card article={article} key={`article__${article.node.id}`} className="fade"/>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
};

const getOrderSmall = (orderArticles) => {
  return ({
    leftArticles: (orderArticles.slice(0, 1)),
    rightArticles: orderArticles.slice(1, orderArticles.length),
  })
}

const getOrderMedium = (orderArticles) => {
  const newLeftArticle = []
  const newRightArticle = []
  orderArticles.map((article, key) => {
    return (key % 2) ? newRightArticle.push(article) : newLeftArticle.push(article)
  })
  return ({
    leftArticles: newLeftArticle,
    rightArticles: newRightArticle,
  })
}

const getOrderBig = (orderArticles) => {
  let change = 0
  let sum = 3
  const newLeftArticle = []
  const newRightArticle = []

  for (let i = 0; i < orderArticles.length; i++) {
    const article = orderArticles[i]
    if (i === change) {
      newLeftArticle.push(article)
      change += sum
      sum = (sum === 3 ? 5 : 3)
    } else {
      newRightArticle.push(article)
    }
  }

  return ({
    leftArticles: newLeftArticle,
    rightArticles: newRightArticle,
  })
}
